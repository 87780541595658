<template>
  <div>
    <h2
      v-if="showHeader"
      class="heading-h5.1 tw-font-normal tw-mb-5 tw-text-primary-300 tw-text-center"
    >
      {{ $t('mainPage.subscribe.joinCommunity') }}
    </h2>

    <div
      class="tw-flex tw-flex-col tw-gap-5 tw-items-center tw-justify-center xl:tw-flex-col"
      :class="{ 'md:tw-flex-row': !center }"
    >
      <ul class="tw-flex tw-gap-2 tw-flex-wrap">
        <li v-for="item in communityList" :key="item.id" class="tw-p-1">
          <de-collapsible-listbox
            v-if="item.linkRu"
            :options="socialLocales"
            append-to="self"
            popup-alignment="center"
            panel-class="tw-p-0"
            list-item-class="tw-py-0.5 tw-bg-primary-800"
            class="tw-leading-600"
            style="--p-anchor-gutter: 6px"
            @change="onChange($event, item)"
          >
            <template #trigger>
              <button class="qwerty">
                <de-svg-icon :name="item.icon" class="tw-w-500 tw-h-500" />
              </button>
            </template>
            <template #option="{ option }">
              <a
                :href="option.code === 'ru' ? item.linkRu : item.link"
                :title="item.id"
                target="_blank"
                rel="noopener"
                class="tw-inline-block tw-text-300 tw-leading-400 tw-uppercase"
              >
                {{ option.label }}
              </a>
            </template>
          </de-collapsible-listbox>

          <a v-else :href="item.link" :title="item.id" target="_blank" rel="noopener">
            <de-svg-icon :name="item.icon" class="tw-w-500 tw-h-500" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DropdownChangeEvent } from 'primevue/dropdown';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import type { SvgIcons } from 'assets/icons/svg-icons';
import DeCollapsibleListbox from '~/shared/components/lib/dropdown/DeCollapsibleListbox.vue';
import locales from '~/assets/languages.json';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface communityListItem {
  id: string;
  icon: SvgIcons;
  link: string;
  linkRu?: string;
}

const communityList: communityListItem[] = [
  {
    id: 'facebook',
    icon: 'socials--facebook-round',
    link: 'https://www.facebook.com/dappexpert',
    linkRu: 'https://www.facebook.com/dapp.expert_russian',
  },
  {
    id: 'telegram',
    icon: 'socials--telegram-round',
    link: 'https://t.me/dappexpert',
    linkRu: 'https://t.me/dappexpert_russia',
  },
  {
    id: 'instagram',
    icon: 'socials--instagram',
    link: 'https://www.instagram.com/dapp.expert/',
    linkRu: 'https://www.instagram.com/dapp.expert_russian/',
  },
  {
    id: 'vk',
    icon: 'socials--ru--vk',
    link: 'https://vk.com/dappexpert',
  },
  { id: 'twitter', icon: 'socials--twitter', link: 'https://twitter.com/Dappexpert' },
  {
    id: 'youtube',
    icon: 'socials--youtube',
    link: 'https://www.youtube.com/@dappexpert',
  },

  {
    id: 'discord',
    icon: 'socials--discord',
    link: 'https://discord.com/channels/873147314410237983/873147315408490568',
  },
  { id: 'reddit', icon: 'socials--reddit', link: 'https://www.reddit.com/user/DappExpert/' },
  { id: 'medium', icon: 'socials--medium', link: 'https://medium.com/@Dapp.Expert' },
];

defineProps({
  showHeader: {
    type: Boolean,
    default: true,
  },
  center: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const socialLocales = computed(() =>
  locales.map((item) => ({
    ...item,
    label: item.code === 'ru' ? t('common.shortLocale.ru') : t('common.shortLocale.en'),
  })),
);

function onChange(event: DropdownChangeEvent, item: communityListItem) {
  if (event.value === 'ru') {
    window.open(item.linkRu, '_blank');
  } else {
    window.open(item.link, '_blank');
  }
}
</script>
