<template>
  <de-dropdown
    v-model="model"
    :options="options"
    :class="['de-collapsible-listbox', `de-collapsible-listbox-${category}`]"
    :placeholder="placeholder"
    :option-label="optionLabel"
    :option-value="optionValue"
    :panel-class="panelClass"
    :append-to="appendTo"
    :popup-alignment="popupAlignment"
    @change="onChange"
  >
    <template #value="slotProps">
      <slot name="trigger" :slot-props="slotProps">
        <de-button
          :label="slotProps.value ? slotProps.value : slotProps.placeholder"
          :icon="iconName"
          :icon-pos="ButtonIconPositionOptions.right"
          class="tw-w-full tw-justify-between"
        />
      </slot>
    </template>

    <template #option="{ option }">
      <slot name="option" :option="option" />
    </template>

    <!-- dirty hack to remove default icon of the prime vue dropdown-->
    <template #dropdownicon> {{ null }} </template>
  </de-dropdown>
</template>

<script setup lang="ts">
import type { DropdownChangeEvent } from 'primevue/dropdown';
import DeDropdown from '~/shared/components/lib/dropdown/DeDropdown.vue';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import { COLLAPSIBLE_LISTBOX_CATEGORY_OPTIONS } from '~/shared/components/lib/dropdown/constants';
import { ButtonIconPositionOptions } from '~/shared/components/lib/button/button.entity';
import type { SvgIcons } from 'assets/icons/svg-icons';

const model = defineModel<any>();

withDefaults(
  defineProps<{
    options: any[];
    optionLabel?: string | (() => string);
    optionValue?: string | (() => string);
    appendTo?: HTMLElement | 'body' | 'self';
    popupAlignment?: 'left' | 'right' | 'center';
    placeholder?: string;
    category?: keyof typeof COLLAPSIBLE_LISTBOX_CATEGORY_OPTIONS;
    iconName?: SvgIcons;
    panelClass?: string;
  }>(),
  {
    popupAlignment: 'left',
    category: COLLAPSIBLE_LISTBOX_CATEGORY_OPTIONS.primary,
    iconName: 'chevron-down-filled',
  },
);

const emit = defineEmits<{
  change: [event: DropdownChangeEvent];
}>();
function onChange(event: DropdownChangeEvent) {
  emit('change', event);
}
</script>
